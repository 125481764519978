export enum USER_REVIEW_STATUS {
  DRAFT = 'draft', // not published
  PUBLISHED = 'published', // as upcoming - start date in future
  ACTIVE = 'active', // start date today or before today

  // - when digi sign disabled: all tasks in this review are completed
  // - when digi sign enabled: all signatures in this review are completed
  COMPLETED = 'completed',
  // When 1 signature is done but not all signatures are completed
  // - only when digi sign setting is enabled in the review(cycle)
  SIGNING = 'signing',
  ARCHIVED = 'archived',
}

export enum USER_REVIEW_SORT_OPTIONS {
  NAME_A_Z = 'NAME_A_Z',
  NAME_Z_A = 'NAME_Z_A',
  START_DATE_NEW_OLD = 'START_DATE_NEW_OLD',
  START_DATE_OLD_NEW = 'START_DATE_OLD_NEW',
  EMPLOYEE_A_Z = 'EMPLOYEE_A_Z',
  EMPLOYEE_Z_A = 'EMPLOYEE_Z_A',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  CREATED_BY_A_Z = 'CREATED_BY_A_Z',
  CREATED_BY_Z_A = 'CREATED_BY_Z_A',
}

export enum USER_REVIEW_REQUEST_TYPE {
  ALL = 'all',
  PERSONAL = 'about-you',
}

export enum USER_REVIEW_PEER_TYPE {
  USER = 'user',
  EMAIL = 'email',
}

export enum USER_REVIEW_QUERY_PARAMS {
  SHOW_NOMINATE_PEERS = 'show-nominate-peers',
  SHOW_SIGNATURE_MODAL = 'show-signature-modal',
  SHOW_PLAN_CONVERSATION_MODAL = 'show-plan-conversation-modal',
  // TODO: Add more custom params when needed
}
