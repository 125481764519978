import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div<{ isPDFView?: boolean }>`
  ${(props) => !props.isPDFView && 'width: 750px;'}
  background-color: ${COLORS.WHITE};
  padding: 24px 32px 26px;
  margin-bottom: 46px;
  border-radius: 6px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  @media print {
    page-break-after: avoid;
    page-break-inside: avoid;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: 500;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.CYAN};
  color: ${COLORS.WHITE};
  width: 24px;
  height: 24px;
  border-radius: 100px;
  margin-right: 9px;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.08);
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  & .header {
    display: flex;
    justify-content: space-between;

    & .date {
      font-size: 12px;
      color: ${COLORS.SUBTEXT};
    }

    // the page should not break after the avatar and  the content
    @media print {
      page-break-after: avoid;
    }
  }

  &:not(:last-child) {
    margin-bottom: 34px;
  }

  @media print {
    page-break-inside: avoid;
  }
`;

export const Content = styled.div<{ color?: string }>`
  color: ${({ color }) => color || 'unset'};
  position: relative;
  margin-top: 7px;
  background-color: ${COLORS.BG_LIST};
  border-radius: 6px;
  padding: 10px 14px;
  box-sizing: border-box;
  font-size: 14px;
  min-height: 48px;
  display: flex;
  flex-direction: column;
  word-break: break-all;

  & .arrow {
    position: absolute;
    top: -5px;
    left: 32px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid ${COLORS.BG_LIST};
  }

  @media print {
    page-break-inside: auto;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const DraftLabel = styled.span`
  width: 44px;
  height: 18px;
  padding: 1px 2px;
  border-radius: 3px;
  border: solid 1px ${COLORS.ACCENT_INFO};
  background-color: ${COLORS.STATUS_PREVIOUS_LIGHT};
  font-size: 12px;
  text-align: center;
`;

export const VisibilityStatus = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 18px;
`;
